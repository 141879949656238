@import url('https://fonts.googleapis.com/css2?family=Koulen&family=Roboto:wght@300;400;500&display=swap');

@tailwind base;
@layer base {
  h1 {
    @apply text-5xl md:text-6xl text-center md:text-left w-fit mx-auto md:mx-0 font-display tracking-wide mb-10 after:mx-auto md:after:mx-0 text-dark dark:text-light;

    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  h2 {
    @apply text-3xl md:text-4xl w-fit mx-auto md:mx-0 font-display tracking-wide mb-6 after:mx-auto md:after:mx-0 text-dark dark:text-light;
  }

  h3 {
    @apply text-lg md:text-xl w-fit mx-auto md:mx-0 font-sans tracking-wide mb-4 after:mx-auto md:after:mx-0 text-dark dark:text-light;
  }

  p {
    @apply font-sans font-light leading-7 text-dark dark:text-light mb-5;
  }

  a, button {
    @apply text-dark dark:text-light;
  }

  .line-hover-dark {
    @apply pb-1;

    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 90%,
      #222222 90%,
      #222222 100%
    );
    background-repeat: no-repeat;
    background-size: 0 100%;
    background-position-x: right;
    transition: background-size 300ms;
  }
  .line-hover-light {
    @apply pb-1;

    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 90%,
      #FAFAFA 90%,
      #FAFAFA 100%
    );
    background-repeat: no-repeat;
    background-size: 0 100%;
    background-position-x: right;
    transition: background-size 300ms;
  }

  .line-hover-light:hover,
  .line-hover-dark:hover {
    background-size: 100% 100%;
    background-position-x: left;
  }

  textarea, input, label {
    @apply text-dark dark:text-light placeholder:text-base placeholder:text-dark-50 dark:placeholder:text-light-50;
  }
}

@tailwind components;
@layer components {
  .card-white {
    background: rgba(211,211,211,.35);
    border: 1px solid rgba(211,211,211,0.18 );
    box-shadow: 0 8px 32px 0 rgba(211,211,211, 0.37 );
  }

  .card-black {
    background: rgba(72,72,72,.35);
    border: 1px solid rgba(72,72,72, 0.18 );
    box-shadow: 0 8px 32px 0 rgba(72,72,72, 0.37 );
  }
}

@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  overscroll-behavior-y: none;
}

h1::after {
  content: "";
  display: block;
  width: 50%;
  height: 24px;
  border-bottom: 5px solid transparent;
  border-image: url(assets/images/mesh-gradient.png) 30 stretch;
}

.new-line {
  white-space: pre-line;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
